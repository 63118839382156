<template>
  <div class="view-home position-relative parents-list-item teacher-list">
    <div class="container">
      <nav class="breadcrumbs-block">
        <ul class="breadcrumbs-list">
          <li class="breadcrumbs-item"><router-link to="/">{{ $t('navbar.home') }}</router-link></li>
          <li class="breadcrumbs-item">{{ $t('breadcrumb.for-teachers') }}</li>
        </ul>
      </nav>
      <div class="col-12 col-lg-6 mx-lg-auto item-block">
        <img :src="getImagePath(teacherData.img)" width="100%" height="100%" alt=""/>
        <div class="description">

        </div>
        <div class="title">
          {{ teacherData[`title_${$i18n.locale}`] }}
        </div>
        <div class="description">
          {{ teacherData[`text_${$i18n.locale}`] }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      teacherData: {},
      backUrl: window.API_ROOT + '/storage/',
    }
  },
  methods: {
    getImagePath(path) {
      if (!path) return

      const formattedPath = encodeURI(path);

      if (formattedPath.startsWith('http://') || formattedPath.startsWith('https://')) {
        return formattedPath;
      } else {
        return this.backUrl + formattedPath;
      }
    },
  },
  mounted() {

    const id = this.$route.params.id
    this.$http.get(window.API_ROOT + `/api/blog-teacher/${id}`)
        .then(res => {
          this.teacherData = res.body
        })
  }


}

</script>

<style>

.item-block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 40px;
  padding-bottom: 40px;
}

.item-block img {
  border-radius: 10px;
}

.item-block .title {
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #343f68;
}

.item-block .description {
  color: #696984;
  font-size: 16px;
}

.teacher-list .breadcrumbs-block {
  margin: 30px 0;
}

.teacher-list .breadcrumbs-block li {
  color: #343f68;
}

.teacher-list .breadcrumbs-block ul li {
  border-right: 1px solid #343f68;
}

.teacher-list .breadcrumbs-block ul li:last-child {
  border-right: none;
}

.teacher-list .breadcrumbs-block a {
  color: #343f68;
}


</style>
